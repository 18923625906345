module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-56447542-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Confluent Hub","short_name":"Confluent Hub","start_url":"/","background_color":"#FFFFFF","theme_color":"#242446","display":"minimal-ui","icon":"src/assets/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"436f18bb987edaec18cff32f9ea8f7e9"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"VbksU2RWQSZUb5dmL899Esl9cC08Rg59","devKey":"VbksU2RWQSZUb5dmL899Esl9cC08Rg59","trackPage":false,"delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
