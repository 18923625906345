// (Copyright) Confluent, Inc.

// @flow
import Cookies from 'js-cookie';

export const getCookie = (key: string): ?string => {
  return Cookies.get(key);
};

export const documentHasCookie = (key: string): boolean => {
  const cookie = Cookies.get(key);

  return cookie != null && cookie !== '';
};
