// @flow
// (Copyright) Confluent, Inc.
import * as React from 'react';
import '@reach/accordion/styles.css';
import '@reach/dialog/styles.css';
import { analytics } from '@confluent/core';

import './src/i18n';
import { getCookieConsentInfo } from './src/components/OneTrust';
import { UserProvider } from './src/providers/provider';

export const wrapRootElement = ({ element }: { element: React.Node }): React.Node => {
  return <UserProvider cookieConsent={getCookieConsentInfo()}>{element}</UserProvider>;
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    // delay analytics to give time for DOM to get loaded. Otherwise title reflects previous page title.
    analytics.isReady && analytics.page();
  }, 1000);
};
