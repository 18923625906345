// @flow
// (Copyright) Confluent, Inc.

import qs from 'query-string';

import { getCookie } from '../utils/cookies';

export const Consents = Object.freeze({
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
  SOCIAL_MEDIA: 'C0005',
});

export type ConsentCategory = $Values<typeof Consents>;
export type ConsentsSet = Set<ConsentCategory>;

export type CookieConsentInfo = {
  consents: ConsentsSet,
  hasUserInteracted: boolean,
  userAcceptedAll: boolean,
  userRejectedAll: boolean,
};

const ONE_TRUST_COOKIE = 'OptanonConsent';

/*
Parse the onetrust cookie to get the categories that the user consented to. 
Ref. https://community.cookiepro.com/s/article/UUID-1e75fb0d-bb79-2af8-cb85-f905d16f1220
*/
// $FlowExpectedError
function allowedCookieCategories(cookie: any): ConsentsSet {
  const allowedCategories = new Set<ConsentCategory>([Consents.STRICTLY_NECESSARY]);
  cookie?.groups?.split(',').forEach((categoryAndConsent) => {
    const [category, consent] = categoryAndConsent.split(':');
    if (consent !== '0') {
      allowedCategories.add(category);
    }
  });
  return allowedCategories;
}

export function getCookieConsentInfo(): CookieConsentInfo {
  const cookie = getCookie(ONE_TRUST_COOKIE);
  if (!cookie) {
    return {
      hasUserInteracted: false,
      consents: new Set<ConsentCategory>([Consents.STRICTLY_NECESSARY]),
      userAcceptedAll: false,
      userRejectedAll: false,
    };
  }
  const parsedCookie = qs.parse(cookie);
  const allowedCategories = allowedCookieCategories(parsedCookie);
  // $FlowExpectedError
  const totalCookieCategories = parsedCookie?.groups?.split(',').length;
  return {
    // $FlowExpectedError
    hasUserInteracted: parsedCookie?.interactionCount > 0 ? true : false,
    consents: allowedCategories,
    // $FlowExpectedError
    userAcceptedAll: allowedCategories.size === totalCookieCategories,
    userRejectedAll: totalCookieCategories !== 1 && allowedCategories.size === 1,
  };
}
