// @flow
// (Copyright) Confluent, Inc.

import { analytics } from '@confluent/core';

import { Consents, ConsentsSet, CookieConsentInfo } from '../components/OneTrust';
import { HUB_SEGMENT_API_KEY_DEV } from '../constants/config';
/*
  This module has utility functions for developers to enable/disable integrations based 
  on user consent. This uses the onetrust cookie compliace integration to determine user consents. 
  For example if the user has consented to performance cookies, we can allow performance related 
  integrations (such as analytics) on that user. 
  
  Onetrust (if integrated) will automatically block/enable device mode integrations, cookies
  and external scripts based on user consent. It will not however block cloud mode integrations
  and integrations that it fails to categorise. Developers can update the 'requiredConsentsForIntegration'
  functions to give the set of consents required for that integration.
*/

const SEGMENT_API_KEY = process.env.HUB_SEGMENT_API_KEY
  ? process.env.HUB_SEGMENT_API_KEY
  : HUB_SEGMENT_API_KEY_DEV;

type SegmentIntegration = {
  category: string,
  creationName: string,
  description: string,
  name: string,
  website: string,
};

// $FlowFixMe
function requiredConsentsForIntegration(integration: SegmentIntegration): ConsentsSet | void {
  /*
  Function that returns the set of consents required to provided by the user, for the given 
  segment integration. Currently we only have a webhook integration under category Raw Data so a simple 
  conditional statement suffices for now. We will revisit this logic if/once more integrations are added. 

  Please update the required consents if we change how an integrations works. For example if we add the
  capablity to track users using some integration then user conset for TARGETING needs to be added to the 
  required consents set. 
  */
  switch (integration.name) {
    case 'Webhooks':
      return new Set([Consents.PERFORMANCE]);
    case 'Amplitude':
      return new Set([Consents.PERFORMANCE]);
    default:
      return undefined;
  }
}

function allowIntegration(
  // $FlowFixMe
  userConsents: CookieConsentInfo,
  // $FlowFixMe
  requiredConsents: ConsentsSet | void
): boolean {
  // we should not allow any integration if its required consents are not set by the developer
  if (requiredConsents === undefined) {
    return false;
  }
  if (userConsents.userAcceptedAll || requiredConsents.has(Consents.STRICTLY_NECESSARY)) {
    return true;
  }
  for (const requiredConsent of requiredConsents.keys()) {
    if (!userConsents.consents.has(requiredConsent)) {
      return false;
    }
  }
  return true;
}

function propertiesForIntegration(integration: SegmentIntegration): mixed | void {
  switch (integration.name) {
    case 'Amplitude':
      return analytics.getAmplitudeProperties();
    default:
      return undefined;
  }
}

async function getIntegrations(): Promise<Array<SegmentIntegration>> {
  const integrations = await window.fetch(
    // $FlowExpectedError
    `https://cdn.segment.com/v1/projects/${SEGMENT_API_KEY}/integrations`
  );
  return await integrations.json();
}

// $FlowFixMe
export async function updateIntegrations(userConsents: CookieConsentInfo): Promise<void> {
  const integrations = await getIntegrations();
  integrations.forEach((integration) => {
    const requiredConsents = requiredConsentsForIntegration(integration);
    const enable = allowIntegration(userConsents, requiredConsents);
    analytics.options.integrations[integration.name] = enable;
    // if enable add the porperties for the corresponding integration (if any)
    const props = propertiesForIntegration(integration);
    if (enable && props) {
      analytics.options.integrations[integration.name] = props;
    }
  });
}

export function loadSegmentAnalytics() {
  if (!analytics.isReady) {
    window?.analytics?.load(SEGMENT_API_KEY);
    analytics.page();
  }
}
