// @flow
// (Copyright) Confluent, Inc.
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { translationEN as coreEN, i18nConfig } from '@confluent/core';

const resources = {
  en: {
    core: coreEN,
  },
};

i18n.use(initReactI18next).init({
  ...i18nConfig,
  resources,
});

export default i18n;
