// @flow
// (Copyright) Confluent, Inc.

import * as React from 'react';

import { getCookieConsentInfo } from '../components/OneTrust';
import { updateIntegrations, loadSegmentAnalytics } from '../utils/segment';

import { UserContext } from './context';

export function UserProvider({
  children,
  cookieConsent,
}: {
  children?: React.Node,
  // $FlowFixMe
  cookieConsent: CookieConsentInfo | null,
}) {
  const [consents, setConsent] = React.useState(cookieConsent);

  React.useEffect(() => {
    window.OptanonWrapper = () => {
      setTimeout(() => {
        setConsent(getCookieConsentInfo());
      }, 1000);
    };
  }, [setConsent]);

  React.useEffect(() => {
    if (consents) {
      let segmentLoadTimeout;
      updateIntegrations(consents).then(() => {
        segmentLoadTimeout = setTimeout(loadSegmentAnalytics, 1000);
      });
      return clearTimeout(segmentLoadTimeout);
    }
  }, [consents]);
  return <UserContext.Provider value={{ consents, setConsent }}>{children}</UserContext.Provider>;
}
