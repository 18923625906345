// @flow
// (Copyright) Confluent, Inc.

import { createContext } from 'react';
import type { Context } from 'react';
import type { CookieConsentInfo } from '@confluent/core';

export type HubUserContext = {
  // $FlowFixMe
  consents: CookieConsentInfo | null,
  // $FlowFixMe
  setConsent: (null | CookieConsentInfo) => void,
};

export const UserContext: Context<HubUserContext | null> = createContext(null);
